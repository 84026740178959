// import React ,{useState,useEffect}from 'react';
// import {useNavigate} from 'react-router-dom';

// const Spinner = () => {
 
//     const [count,setCount ]=useState(5);
//     const navigate = useNavigate()

//     useEffect(()=>{
//         const interval = setInterval(() => {
//             setCount((preValue) => --preValue)
//         },1000)
//         count === 0 &&  navigate('/login')
//         return ()  =>  clearInterval(interval)
//     },[count, navigate]);

//   return (
//    <>
//    <div className="d-flex flex-column justify-content-center align-item-center"
//    style={{height:'100vh'}}
//    >
//     <h1 className='text-center'>Redirecting to you in {count} seconds</h1>
//   <div className="spinner-border text-center" role="status">
//     <span className="text-center visually-hidden">Loading...</span>
//   </div>
// </div>
//    </>
//   )
// }

// export default Spinner

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Spinner = ({ path = "login" }) => {
  const [count, setCount] = useState(2);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevValue) => --prevValue);
    }, 1000);
    count === 0 &&
      navigate(`/${path}`, {
        state: location.pathname,
      });
    return () => clearInterval(interval);
  }, [count, navigate, location, path]);
  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <h1 className="Text-center">redirecting to you in {count} second </h1>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  );
};

export default Spinner;

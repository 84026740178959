import React from "react";
import Layout from "../../components/layout/Layout";

const Dashboard = () => {
  return (
    <Layout title={Dashboard}>
      <div>.</div>
    </Layout>
  );
};

export default Dashboard;
